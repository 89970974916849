@import '~antd/dist/antd.css';

body {
  font-family: Montserrat, sans-serif;
  line-height: 1.35;
  font-size: 14px;
  overflow-x: hidden;
  position: relative;
  min-width: 320px;
  color: #293c4d;
  background: #f6a6a7 url(/images/login-screen.jpg?3c835e7…) no-repeat 50% /
    cover;
  padding-top: 40px !important;
}

.ant-layout {
  box-shadow: 0 2px 4px rgba(41, 60, 77, 0.05);
  border-radius: 2px;
  background-color: #fff;
  position: relative;
}

.ant-layout-header {
  box-shadow: 0 2px 4px rgba(41, 60, 77, 0.05);
}

h1 {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  width: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body,
html {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  height: 100%;
}

.logo {
  height: 20px;
  margin: 0 auto;
  display: block;
}
